<template>
  <div class="myEscalation">
    <div class="tab">
      <div
        v-for="(item, index) in tabList"
        :key="index"
        :class="['item', { active: requestData.role == item.key }]"
        @click="selectMem(item, index)"
      >
        <span>{{ item.label }} </span>
      </div>
    </div>
    <div class="main">
      <v-list
        :finished="finished"
        @onLoad="onLoad"
        ref="list"
        :finishedText="finishedText"
        :commonOperaParams="commonOperaParams"
      >
        <div
          class="li"
          v-for="(item, index) in listData"
          :key="index"
          @click="toDetail(item)"
        >
          <div class="li-t">
            <div class="li-tl">{{ item.orderTitle }}</div>
            <div :class="['li-tr', 'te' + item.orderStatus]">
              {{ statusTypeMap[item.orderStatus] }}
            </div>
          </div>
          <div class="li-b">
            <div class="li-bl"><img :src="item.imageUrl" alt="" /></div>
            <div class="li-br">
              <div class="h4">{{ item.orderDisc }}</div>
              <div class="date">{{ item.createTime }}</div>
            </div>
          </div>
        </div>
      </v-list>
    </div>
  </div>
</template>

<script>
import { statusTypeMap } from "./map";
import { getOrderInfoPageListUrl } from "./api";
export default {
  name: "myEscalation",
  data() {
    return {
      statusTypeMap,
      finishedText: "没有更多了",
      finished: false,
      commonOperaParams: {
        showMy: false,
        isCommonOpera: false,
      },
      requestData: {
        role: 0,
        curPage: 1,
        pageSize: 10,
      },
      tabList: [
        { label: "待处置", key: "0" },
        { label: "处置中", key: "1" },
        { label: "已完成", key: "2" },
      ],
      type: "热门活动",
      listData: [],
    };
  },
  mounted() {},
  computed: {
    userId() {
      return this.$store.state.userId;
    },
    role() {
      return this.$store.state.role;
    },
  },
  methods: {
    toDetail(item) {
      this.$router.push({
        name: "escalationDetail",
        query: {
          orderId: item.orderId,
        },
      });
    },
    selectMem(item, index) {
      this.finished = false;
      this.requestData.role = item.key;
      this.requestData.curPage = 1;
      this.listData = [];
      this.getList();
    },
    getList() {
      let params = {
        orderType: "",
        area: "",
        userId: this.userId,
        orderStatus: this.requestData.role,
        curPage: this.requestData.curPage++,
        pageSize: this.requestData.pageSize,
        role: this.role,
        disposeUserId: "",
      };
      this.$axios.post(`${getOrderInfoPageListUrl}`, params).then((res) => {
        if (res.code === 200) {
          if (res.data.pages <= res.data.current) {
            this.finished = true;
          } else {
            this.$refs.list._data.loading = false;
          }
          this.finishedText = "没有更多了";
          if (res.data.records.length === 0) {
            this.finishedText = "";
            return;
          }
          res.data.records.forEach((item) => {
            this.listData.push(item);
          });
        } else {
        }
      });
    },
    onLoad() {
      this.getList();
    },
  },
};
</script>
<style lang="less" scoped>
.myEscalation {
  min-height: 100%;
  background: #fafafaff;
  box-sizing: border-box;
  .tab {
    display: flex;
    justify-content: space-around;
    padding: 40px 30px 18px;
    box-sizing: border-box;
    position: fixed;
    z-index: 999;
    width: 100%;
    top: 0;
    background: #ffffff;
    font-weight: 400;
    font-size: 28px;
    color: rgba(0, 0, 0, 0.5);
    line-height: 40px;
    span {
      font-weight: 400;
      font-size: 28px;
      color: rgba(0, 0, 0, 0.5);
      line-height: 40px;
    }
    .active {
      span {
        font-weight: 700;
        font-size: 32px;
        color: #000000;
        line-height: 44px;
        position: relative;
        &::after {
          content: "";
          position: absolute;
          bottom: -12px;
          left: 0;
          width: 114px;
          height: 12px;
          background: linear-gradient(
            270deg,
            #3d7bfa 0%,
            rgba(112, 178, 253, 0) 100%
          );
          border-radius: 6px;
        }
      }
    }
  }
  .main {
    padding: 120px 30px 0;
    .li {
      height: 268px;
      background: #ffffff;
      border-radius: 16px;
      margin-bottom: 20px;
      padding: 0 34px;
      box-sizing: border-box;
      .li-t {
        padding: 36px 0 18px;
        display: flex;
        justify-content: space-between;
        border-bottom: 2px solid rgba(0, 0, 0, 0.05);
        .li-tl {
          font-weight: 700;
          font-size: 32px;
          color: #333333;
          line-height: 44px;
          position: relative;
          padding-left: 34px;
          &::before {
            content: "";
            width: 12px;
            height: 12px;
            background: #3d7bfa;
            border-radius: 50%;
            position: absolute;
            left: 0;
            top: 0;
            bottom: 0;
            margin: auto;
          }
        }
        .li-tr {
          width: 120px;
          height: 40px;
          background: rgba(255, 206, 111, 0.2);
          border-radius: 4px;
          font-weight: 700;
          font-size: 24px;
          color: #ffce6f;
          line-height: 40px;
          text-align: center;
        }
        .te0 {
        }
        .te1 {
          width: 120px;
          height: 40px;
          background: rgba(61, 123, 250, 0.2);
          border-radius: 4px;
          font-weight: 700;
          font-size: 24px;
          color: #3d7bfa;
          line-height: 40px;
          text-align: center;
        }
        .te2 {
          width: 120px;
          height: 40px;
          background: rgba(61, 123, 250, 0.2);
          border-radius: 4px;
          font-weight: 700;
          font-size: 24px;
          color: #3d7bfa;
          line-height: 40px;
          text-align: center;
        }
      }
      .li-b {
        margin-top: 16px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .li-bl {
          width: 210px;
          height: 118px;
          margin-right: 14px;
          flex-shrink: 0;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .li-br {
          .h4 {
            font-weight: 700;
            font-size: 26px;
            color: rgba(0, 0, 0, 0.85);
            line-height: 36px;
            text-overflow: -o-ellipsis-lastline;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            line-clamp: 2;
            -webkit-box-orient: vertical;
          }
          .date {
            font-weight: 400;
            font-size: 24px;
            color: rgba(0, 0, 0, 0.5);
            line-height: 34px;
            margin-top: 10px;
          }
        }
      }
    }
  }
}
</style>
